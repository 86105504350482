import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import HomeIcon from "@mui/icons-material/Home";
import Box from "@mui/material/Box";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonIcon from "@mui/icons-material/Person";
import LiveHelpIcon from "@mui/icons-material/LiveHelp";
import SettingsIcon from "@mui/icons-material/Settings";
import ChatIcon from '@mui/icons-material/Chat';
import { ListItemButton, useTheme } from "@mui/material";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { isManager } from "../utils/authutils.js";

const TabBarButton = styled(BottomNavigationAction)({
	color: "#f9bfa0",
	"&.Mui-selected": {
		color: "#ffffff",
	},
});
const routes = [
	{ label: "home", icon: HomeIcon, to: "/home" },
	{ label: "profile", icon: PersonIcon, to: "/profile" },
	{ label: "chat", icon: ChatIcon, to: "/home/Chat" },
	{ label: "contact support", icon: LiveHelpIcon, to: "/home/Support" },
];

if (isManager()) {
	routes.push({
		label: "profile_manager",
		icon: SupervisorAccountIcon,
		to: "/profile_manager/profile/review",
	});
}
const BottomNavBar = (props) => {
	const navigate = useNavigate();
	const location = useLocation().pathname.split("/").pop();
	const [screen, setScreen] = React.useState("");
	React.useEffect(() => {
		setScreen(location);
	}, [useNavigate()]);
	const theme = useTheme();

	return (
		<Box>
			<BottomNavigation
				showLabels
				value={screen}
				style={{
					background: theme.palette.primary.main,
					height: "8vh",
				}}
			>
				{routes.map((route, index) => (
					<TabBarButton
						key={index}
						value={route.label}
						icon={<route.icon />}
						component={ListItemButton}
						onClick={() => {
							navigate(route.to);
						}}
					/>
				))}
			</BottomNavigation>
		</Box>
	);
};

BottomNavBar.propTypes = {};

export default BottomNavBar;
