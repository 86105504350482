import React from "react";
import { Route } from "react-router-dom";
import PrimaryLayout from "../_layouts/primary";
import HomePage from "../Pages/Home";
import Search from "../Pages/Home/search";
import Recent_Joined from "../Pages/Home/recent_joined";
import Matched from "../Pages/Home/matched";
import Short_listed from "../Pages/Home/short_listed";
import BottomNavBar from "../components/bottomNavBar";
import Template from "../_layouts/template";

import Notifications from "../Pages/Notifications";
import HeaderTabPanel from "../components/headerTabPanel";
import Privacy from "../Pages/Privacy";
import Support from "../Pages/Support";
import Chat from "../Pages/MatrixChat";
import viewCompleteProfile from "../Pages/Home/viewCompleteProfile";
import { search_criteria } from "../Pages/Profile/searchCriteria";
import { SearchProfiles } from "../Pages/Profile/searchedProfiles";

const Home = (
	<Route element={<PrimaryLayout isHeader={true} />} path="/home">
		<Route
			path=""
			element={
				<Template
					children={HomePage}
					screenTitle="Home"
					bottomNav={<BottomNavBar />}
					headerContents={<HeaderTabPanel />}
				/>
			}
		/>
		<Route
			path="search-criteria"
			element={
				<Template
					children={search_criteria}
					screenTitle="Discover Your Perfect Match"
				/>
			}
		/>
		<Route
			path="search-criteria/searchedprofiles"
			element={
				<Template children={SearchProfiles} screenTitle="Searched Profiles" />
			}
		/>
		<Route
			path="search-criteria/searchedprofiles/viewCompleteProfile/:profileId"
			element={
				<Template children={viewCompleteProfile} screenTitle="View Profile" />
			}
		/>
		<Route
			path="notifications"
			element={
				<Template
					children={Notifications}
					screenTitle="Notifications"
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="Privacy"
			element={
				<Template
					children={Privacy}
					screenTitle="Privacy Notice"
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="Support"
			element={
				<Template
					children={Support}
					screenTitle="Contact Us"
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="Chat"
			element={
				<Template
					children={Chat}
					screenTitle="Chat"
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="search"
			element={
				<Template
					children={Search}
					screenTitle="Search"
					bottomNav={<BottomNavBar />}
				/>
			}
		/>
		<Route
			path="recent-joined"
			element={
				<Template
					children={Recent_Joined}
					screenTitle="Home"
					bottomNav={<BottomNavBar />}
					headerContents={<HeaderTabPanel />}
				/>
			}
		/>
		<Route
			path="matched"
			element={
				<Template
					children={Matched}
					screenTitle="Home"
					bottomNav={<BottomNavBar />}
					headerContents={<HeaderTabPanel />}
				/>
			}
		/>
		<Route
			path="short-listed"
			element={
				<Template
					children={Short_listed}
					screenTitle="Home"
					bottomNav={<BottomNavBar />}
					headerContents={<HeaderTabPanel />}
				/>
			}
		/>
		<Route
			path={`recent-joined/viewCompleteProfile/:profileId`}
			element={
				<Template children={viewCompleteProfile} screenTitle="View Profile" />
			}
		/>
	</Route>
);

export default Home;
