import React, { useEffect, useState, useContext } from "react";
import { observer } from "mobx-react";
import { WebStoreContext } from "../store/web.store";
import styled from "@emotion/styled";
import { useTheme } from "@mui/material/styles";

// Styled components
const MainCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: ${(props) => props.theme.palette.background.default};
`;

const ChatContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1200px;
  height: 80vh;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: hidden;
  background-color: #fff;
`;

const RoomList = styled.div`
  width: 30%;
  background-color: ${(props) => props.theme.palette.primary.light};
  color: #fff;
  padding: 10px;
  border-right: 1px solid #ccc;
  overflow-y: auto;
`;

const RoomItem = styled.div`
  padding: 15px;
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px;
  background-color: ${(props) =>
    props.selected ? props.theme.palette.primary.main : "transparent"};
  color: ${(props) =>
    props.selected ? "#fff" : props.theme.palette.text.primary};
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.main};
    color: #fff;
  }
`;

const MessagesContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.palette.background.paper};
`;

const ChatHeader = styled.div`
  padding: 10px;
  border-bottom: 1px solid #ccc;
  background-color: ${(props) => props.theme.palette.primary.dark};
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MessageList = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: ${(props) =>
    props.isOwnMessage ? props.theme.palette.primary.light : "#f0f0f0"};
  align-self: ${(props) => (props.isOwnMessage ? "flex-end" : "flex-start")};
  max-width: 60%;
  position: relative;

  &:after {
    content: '${(props) => props.timestamp}';
    display: block;
    font-size: 0.75em;
    color: ${(props) => props.theme.palette.text.secondary};
    text-align: right;
    margin-top: 5px;
  }

  ${(props) => !props.body && `
    font-style: italic;
    color: ${props.theme.palette.text.secondary};
  `}
`;

const NewMessageForm = styled.form`
  display: flex;
  gap: 10px;
  padding: 10px;
  border-top: 1px solid #ccc;
`;

const NewMessageInput = styled.input`
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
`;

const SendMessageButton = styled.button`
  background-color: ${(props) => props.theme.palette.primary.main};
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: ${(props) => props.theme.palette.primary.dark};
  }
`;

// Chat component
const Chat = observer(() => {
  const theme = useTheme();
  const store = useContext(WebStoreContext);
  const matrixStore = store.matrixChat;

  const { client, rooms, selectedRoomId, messages, isSynced, sendMessage, setSelectedRoomId, pendingInvitations, acceptInvitation, declineInvitation } = matrixStore;
  const [newMessage, setNewMessage] = useState("");

  useEffect(() => {
    if (!client) {
      matrixStore.initializeClient();
    }
  }, [client, matrixStore]);

  useEffect(() => {
    if (isSynced && selectedRoomId) {
      matrixStore.checkInvitation(selectedRoomId);
    }
  }, [client, isSynced, selectedRoomId, matrixStore]);

  const handleAccept = (roomId) => {
    acceptInvitation(roomId);
  };

  const handleDecline = (roomId) => {
    declineInvitation(roomId);
  };

  return (
    <MainCard theme={theme}>
      <ChatContainer>
        <RoomList theme={theme}>
          {rooms.length > 0 ? (
            rooms.map((room) => (
              <RoomItem
                key={room.roomId}
                selected={room.roomId === selectedRoomId}
                onClick={() => setSelectedRoomId(room.roomId)}
                theme={theme}
              >
                {room.name || room.roomId}
              </RoomItem>
            ))
          ) : (
            <div>Loading rooms...</div>
          )}
        </RoomList>
        <MessagesContainer theme={theme}>
          {selectedRoomId ? (
            <>
              <ChatHeader theme={theme}>
                <h3>{rooms.find((room) => room.roomId === selectedRoomId)?.name}</h3>
              </ChatHeader>
              <MessageList theme={theme}>
                {pendingInvitations.has(selectedRoomId) ? (
                  <div>
                    <p>You have been invited to join this room.</p>
                    <button onClick={() => handleAccept(selectedRoomId)}>Accept</button>
                    <button onClick={() => handleDecline(selectedRoomId)}>Decline</button>
                  </div>
                ) : (
                  messages.map((message, index) => (
                    <Message
                      key={index}
                      isOwnMessage={message.sender === client.credentials.userId}
                      timestamp={message.timestamp}
                      body={message.body}
                      theme={theme}
                    >
                      {message.body}
                    </Message>
                  ))
                )}
              </MessageList>
              {!pendingInvitations.has(selectedRoomId) && (
                <NewMessageForm
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendMessage(selectedRoomId, newMessage);
                    setNewMessage("");
                  }}
                  theme={theme}
                >
                  <NewMessageInput
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    theme={theme}
                  />
                  <SendMessageButton type="submit" theme={theme}>
                    Send
                  </SendMessageButton>
                </NewMessageForm>
              )}
            </>
          ) : (
            <div>Select room/user to see messages</div>
          )}
        </MessagesContainer>
      </ChatContainer>
    </MainCard>
  );
});

export default Chat;
