import { createContext } from "react";
import { HomeInfo } from "./home";
import { UserInfo } from "./userInfo";
import { ProfileInfo } from "./profile";
import { MatrixStore } from "./matrixstore.js";

class webStore {
	Home = new HomeInfo();
	user = new UserInfo();
	profile = new ProfileInfo();
	matrixChat = new MatrixStore();
}

export const WebStore = new webStore();
export const WebStoreContext = createContext(WebStore);
