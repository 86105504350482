import axios from 'axios';

const homeserverUrl = 'https://matrix.milana.tech';

// Function to check if a room already exists between two users
async function checkIfRoomExists(accessToken, deviceId, userId1, userId2) {
  try {
    const response = await axios.get(
      `${homeserverUrl}/_matrix/client/v3/joined_rooms`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );

    const joinedRooms = response.data.joined_rooms;

    for (const roomId of joinedRooms) {
      const membersResponse = await axios.get(
        `${homeserverUrl}/_matrix/client/v3/rooms/${roomId}/joined_members`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }
      );

      const members = Object.keys(membersResponse.data.joined);

      if (members.length === 2 && members.includes(userId1) && members.includes(userId2)) {
        // Check if the room is a direct room
        const roomStateResponse = await axios.get(
          `${homeserverUrl}/_matrix/client/v3/rooms/${roomId}/state`,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
              'Content-Type': 'application/json'
            }
          }
        );

        const isDirectRoom = roomStateResponse.data.some(event =>
          event.type === 'm.room.create' && event.content.is_direct
        );

        if (isDirectRoom) {
          return roomId;
        }
      }
    }

    return null;
  } catch (error) {
    console.error('Error checking if room exists:', error);
    return null;
  }
}

async function reauthenticateUser() {
  try {
    const response = await axios.post(`${homeserverUrl}/_matrix/client/v3/login`, {
      type: "m.login.password",
      user: localStorage.getItem("matrixChatUserId"),
      password: "test123" 
    });

    const { access_token, device_id, user_id } = response.data;

    // Store the new access token and device ID
    localStorage.setItem("matrixChatAccessToken", access_token);
    localStorage.setItem("matrixChatDeviceId", device_id);
    localStorage.setItem("matrixChatUserId", user_id);

    return { access_token, device_id, user_id };
  } catch (error) {
    console.error("Failed to re-authenticate user:", error);
    return null;
  }
}

async function createRoomAndInviteUser(creatorUserId, inviteeUserId) {
  let accessToken = localStorage.getItem("matrixChatAccessToken");
  let deviceId = localStorage.getItem("matrixChatDeviceId");
  let userId = localStorage.getItem("matrixChatUserId");

  if (!accessToken || !deviceId || !userId) {
    const result = await reauthenticateUser();
    if (result) {
      accessToken = result.access_token;
      deviceId = result.device_id;
      userId = result.user_id;
    } else {
      console.error("Unable to authenticate the user.");
      return;
    }
  }

  try {
    // Check if the room already exists
    const existingRoomId = await checkIfRoomExists(accessToken, deviceId, creatorUserId, inviteeUserId);

    if (existingRoomId) {
      console.log(`Room already exists between ${creatorUserId} and ${inviteeUserId}. Room ID: ${existingRoomId}`);
      return existingRoomId;
    }

    // Step 1: Create the Room
    console.log("Creating room for:", creatorUserId, "and inviting:", inviteeUserId);

    const createRoomResponse = await axios.post(
      `${homeserverUrl}/_matrix/client/v3/createRoom`,
      {
        is_direct: true,
        creation_content: {
          "m.federate": false // This prevents the room from being federated to other servers
        },
      },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );

    const roomId = createRoomResponse.data.room_id;

    // Step 2: Have the creatorUserId join the room
    await axios.post(
      `${homeserverUrl}/_matrix/client/v3/rooms/${roomId}/join`,
      {},
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );

    // Step 3: Invite the Other User
    await axios.post(
      `${homeserverUrl}/_matrix/client/v3/rooms/${roomId}/invite`,
      { user_id: inviteeUserId },
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        }
      }
    );

    console.log(`Room created by ${creatorUserId} and ${inviteeUserId} invited successfully. Room ID: ${roomId}`);
    return roomId;
  } catch (error) {
    if (error.response && error.response.data && error.response.data.errcode === 'M_UNKNOWN_TOKEN') {
      console.error('Access token expired. Re-authenticating...');
      const result = await reauthenticateUser();
      if (result) {
        accessToken = result.access_token;
        deviceId = result.device_id;
        userId = result.user_id;
        return createRoomAndInviteUser(creatorUserId, inviteeUserId); // Retry the operation with new token
      } else {
        console.error('Re-authentication failed.');
      }
    } else {
      console.error('Error creating room or inviting user:', error);
    }
    return null;
  }
}

export { createRoomAndInviteUser };
