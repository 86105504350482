import { makeAutoObservable, runInAction } from "mobx";
import {
	fetchProfilesByStatus,
	updateProfileStatus,
	fetchProfileData,
	updateProfile,
	createProfile,
	fetchProfileCount,
	searchProfiles,
	deleteMedia,
} from "../api/Profile";
import { makePersistable } from "mobx-persist-store";

export class ProfileInfo {
	profileCount = 0;
	formData = {};

	profileFetched = false;
	profileDetails = {
		fetchedData: {},
		updatedData: {},
		searchedData: {},
	};
	managerProfile = {
		fetchedProfiles: {
			currentPage: 0,
			message: "",
			perPage: 0,
			profiles: [],
			totalPages: 0,
			total_count: 0,
		},
		updatedProfiles: [],
	};
	loading = true;

	constructor() {
		makeAutoObservable(this);

		makePersistable(this, {
			name: "ProfileInfo",
			properties: [
				"profileDetails",
				"managerProfile",
				"formData",
				"profileCount",
			],
			storage: window.localStorage,
		}).catch((error) =>
			console.error("Failed to make ProfileStore persistable:", error)
		);
	}

	filterObjectByKeys(obj, allowedKeys) {
		return Object.fromEntries(
			Object.entries(obj).filter(([key]) => allowedKeys.includes(key))
		);
	}

	updateFormData(filteredData) {
		const allowedKeys = [
			"createdBy",
			"personalInformation",
			"educationCareer",
			"familyCulturalContext",
			"lifestyleAndHobbies",
			"aFewLinesAboutYourself",
			"partnerPreferences",
			"anythingYouWantToShare",
			"media"
		];
		this.formData = this.filterObjectByKeys(filteredData, allowedKeys);
	}

	async updateManagerProfileStatus(profileId, newStatus, userEmail) {
		this.loading = true;
		try {
			const updateResult = await updateProfileStatus(
				profileId,
				newStatus,
				userEmail
			);
			console.log("Manager profile status updated:", updateResult);
		} catch (error) {
			console.error("Error updating manager profile status:", error);
		} finally {
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async fetchManagerProfile(status) {
		this.loading = true;
		try {
			const response = await fetchProfilesByStatus(status);
			console.log("Fetched profiles:", response);
			runInAction(() => {
				if (response && response.profiles) {
					this.managerProfile.fetchedProfiles = response;
				} else {
					console.error("Unexpected response structure:", response);
				}
				this.loading = false;
			});
		} catch (error) {
			console.error("Error fetching manager profiles by status:", error);
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async fetchProfileData() {
		this.loading = true;
		try {
			const response = await fetchProfileData();
			runInAction(() => {
				console.log("response", response);
				this.profileDetails.fetchedData = response.profile || {};
				this.updateFormData(response.profile);
				this.profileFetched = true;
				this.loading = false;
			});
		} catch (error) {
			console.error("Error fetching profile data:", error);
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async updateProfileData(data, action, profileId, refresh = false) {
		this.loading = true;
		try {
			const response = await updateProfile(action, data, profileId);
			if (refresh) {
				await this.fetchProfileData();
			} else {
				runInAction(() => {
					this.loading = false;
				});
			}
			return response;
		} catch (error) {
			console.error("Error updating profile data:", error);
			runInAction(() => {
				this.loading = false;
			});
		}
	}
	async createProfileData() {
		this.loading = true;
		try {
			const response = await createProfile();
			runInAction(() => {
				console.log("Profile created successfully:", response);
				this.loading = false;
			});
		} catch (error) {
			console.error("Error creating profile:", error);
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async fetchProfileCount(milUserId) {
		this.loading = true;
		try {
			const count = await fetchProfileCount(milUserId);
			runInAction(() => {
				this.profileCount = count;
				this.loading = false;
			});
		} catch (error) {
			console.error("Error fetching profile count:", error);
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async searchProfiles(queryString) {
		this.loading = true;
		try {
			const response = await searchProfiles(queryString);
			runInAction(() => {
				console.log("Fetched profiles based on search criteria:", response);
				this.profileDetails.searchedData = response;
				this.loading = false;
			});
		} catch (error) {
			console.error("Error searching profiles:", error);
			runInAction(() => {
				this.loading = false;
			});
		}
	}

	async handleDeleteMedia(profileId, keyName) {
		try {
			const response = await deleteMedia(profileId, keyName);
			console.log("Media deletion response:", response);
		} catch (error) {
			console.error("Error deleting media:", error);
		}
	}

	clearProfiles() {
		runInAction(() => {
			this.managerProfile.fetchedProfiles = {
				currentPage: 0,
				message: "",
				perPage: 0,
				profiles: [],
				totalPages: 0,
				total_count: 0,
			};
			this.managerProfile.updatedProfiles = [];
		});
	}
}
