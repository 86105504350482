import { makeAutoObservable } from "mobx";
import sdk from "matrix-js-sdk";

function debounce(func, wait) {
  let timeout;
  return function(...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export class MatrixStore {
  client = null;
  rooms = [];
  selectedRoomId = null;
  messages = [];
  isSynced = false;
  pendingInvitations = new Map();

  constructor() {
    makeAutoObservable(this);
    this.debouncedSendMessage = debounce(this.sendMessage, 300); // Adjust debounce delay as needed
  }

  initializeClient() {
    if (this.client) return; // Avoid re-initializing if already initialized

    this.client = sdk.createClient({
      baseUrl: "https://matrix.milana.tech",
      accessToken: localStorage.getItem("matrixChatAccessToken"),
      userId: localStorage.getItem("matrixChatUserId"),
      deviceId: localStorage.getItem("matrixChatDeviceId"),
    });

    this.client.on("sync", (state) => {
      console.log(`Matrix sync state: ${state}`);
      if (state === "PREPARED") {
        this.isSynced = true;
        this.rooms = this.client.getRooms();
      }
    });

    this.client.on("Room.timeline", (event, room) => {
      if (room.roomId === this.selectedRoomId) {
        const content = event.getContent();
        const message = {
          body: content.body,
          sender: event.getSender(),
          timestamp: new Date(event.getTs()).toLocaleString(),
        };

        if (message.body) {
          this.messages.push(message);
        }
      }
    });

    this.client.on("RoomMember.membership", (event, member) => {
      if (member.roomId === this.selectedRoomId) {
        this.handleInvitation(event, member);
      }
    });

    this.client.startClient({ initialSyncLimit: 10 });
  }

  sendMessage = (roomId, message) => {
    if (this.client && message) {
      const room = this.client.getRoom(roomId);
      if (room) {
        this.client.sendEvent(
          roomId,
          "m.room.message",
          { body: message, msgtype: "m.text" },
          "",
          (err) => {
            if (err) {
              console.error("Message sending failed", err);
            }
          }
        );
      } else {
        console.error("Room not found:", roomId);
      }
    }
  };

  setSelectedRoomId = (roomId) => {
    if (this.client) {
      this.selectedRoomId = roomId;
      this.messages = [];
      const room = this.client.getRoom(roomId);
      if (room) {
        this.pendingInvitations.delete(roomId); // Clear pending invitation if room is found
        room.timeline.forEach((event) => {
          const content = event.getContent();
          const message = {
            body: content.body,
            sender: event.getSender(),
            timestamp: new Date(event.getTs()).toLocaleString(),
          };

          if (message.body) {
            this.messages.push(message);
          }
        });
      } else {
        this.checkInvitation(roomId); // Check for invitation if room is not found
      }
    }
  };

  checkInvitation = (roomId) => {
    if (this.client) {
      const room = this.client.getRoom(roomId);
      if (room) {
        const member = room.getMember(this.client.getUserId());
        if (member && member.membership === "invite") {
          this.pendingInvitations.set(roomId, true);
        } else {
          this.pendingInvitations.delete(roomId);
        }
      }
    }
  };

  handleInvitation = (event, member) => {
    if (member.membership === "invite" && member.roomId === this.selectedRoomId) {
      console.log("You have been invited to a room");
      this.pendingInvitations.set(member.roomId, true);
    }
  };

  acceptInvitation = (roomId) => {
    if (this.client) {
      this.client.joinRoom(roomId).then(() => {
        console.log(`Joined room: ${roomId}`);
        this.pendingInvitations.delete(roomId);
        this.setSelectedRoomId(roomId);
      }).catch((err) => {
        console.error(`Failed to join room: ${roomId}`, err);
      });
    }
  };

  declineInvitation = (roomId) => {
    if (this.client) {
      this.client.leaveRoom(roomId).then(() => {
        console.log(`Declined invitation to room: ${roomId}`);
        this.pendingInvitations.delete(roomId);
        this.setSelectedRoomId(null);
      }).catch((err) => {
        console.error(`Failed to decline invitation to room: ${roomId}`, err);
      });
    }
  };
}
