import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { WebStoreContext } from "../../store/web.store";
import { createRoomAndInviteUser } from "../../utils/chatutils";

import {
	Container,
	Typography,
	Grid,
	Card,
	CardContent,
	CardMedia,
	Box,
	LinearProgress,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Selection } from "@milana/web-client";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const sliderDotStyles = `
  .slick-dots li button:before {
    font-size: 18px;
    color: #ff5722;
  }
  .slick-dots li.slick-active button:before {
    color: #f16e26;
  }
`;

const convertToFormCase = (key) => {
	return key
		.replace(/([A-Z])/g, " $1")
		.replace(/^./, (str) => str.toUpperCase());
};


const ViewCompleteProfile = () => {
	const [profileData, setProfileData] = useState(null);
	const [loading, setLoading] = useState(true);
	const [loadingImages, setLoadingImages] = useState(true);
	const [loadingVideos, setLoadingVideos] = useState(true);
	const [error, setError] = useState(null);
	const navigation = useNavigate();
	const store = useContext(WebStoreContext);
	const params = useParams();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const profileId = params.profileId;
				await store.Home.loadCompleteProfileById(profileId);
				const profile = store.Home.completeProfile;
				if (profile) {
					setProfileData(profile);
					setLoading(false);
				} else {
					setError("Profile data not found");
					setLoading(false);
				}
			} catch (error) {
				console.error("Failed to fetch profile data", error);
				setError("Failed to fetch profile data");
				setLoading(false);
			}
		};
		fetchData();
	}, [params.profileId, store.Home]);

	const handleImageLoad = () => {
		setLoadingImages(false);
	};

	const handleVideoLoad = () => {
		setLoadingVideos(false);
	};

	const InitiateChat = (profileData) => {
	const user1 = localStorage.getItem("matrixChatUserId");
	const user2 = profileData.matrixChatUserId;

	if (user2) {
		createRoomAndInviteUser(user1, user2)
		.then(roomId => {
			if (roomId) {
				console.log('Room ID:', roomId);
				navigation("/home/Chat");			
			}
		}
	);
	}
};
	const renderKeyValuePair = (data) => {
		if (!data) {
			return null;
		}
		return Object.entries(data).map(([key, value], index) => (
			<Grid
				container
				key={index}
				spacing={2}
				alignItems="center"
				sx={{
					mt: 1,
					mb: 1,
					flexDirection: { xs: "column", sm: "row" },
				}}
			>
				<Grid
					item
					xs={12}
					sm={4}
					md={3}
					sx={{
						paddingRight: { sm: "10px" },
					}}
				>
					<Typography
						variant="body1"
						sx={{
							fontWeight: "bold",
							fontSize: { xs: "1rem", sm: "1rem" },
						}}
					>
						{convertToFormCase(key)}:
					</Typography>
				</Grid>
				<Grid item xs={12} sm={8} md={9}>
					<Typography
						variant="body1"
						sx={{
							color: "#ff5722",
							fontSize: { xs: "1rem", sm: "1rem" },
						}}
					>
						{Array.isArray(value)
							? renderArrayValues(value)
							: typeof value === "object"
							? renderObjectValues(value)
							: value || "N/A"}
					</Typography>
				</Grid>
			</Grid>
		));
	};

	const renderKeyValuePairs = (data) => {
		if (!data) {
			return null;
		}
		return Object.entries(data).map(([key, value], index) => (
			<Grid container key={index} spacing={2}>
				<Grid item xs={6} sm={6} md={4} lg={3}>
					<Typography variant="body1">
						<strong>{convertToFormCase(key)}:</strong>
					</Typography>
				</Grid>
				<Grid item xs={6} sm={6} md={8} lg={9}>
					<Typography variant="body1" style={{ color: "#ff5722" }}>
						{Array.isArray(value)
							? renderArrayValues(value)
							: typeof value === "object"
							? renderObjectValues(value)
							: value || "N/A"}
					</Typography>
				</Grid>
			</Grid>
		));
	};

	const renderArrayValues = (arrayData) => {
		return (
			<span style={{ color: "#ff5722" }}>
				{arrayData.map((item, index) => (
					<React.Fragment key={index}>
						{index > 0 && ", "}
						{typeof item === "object" ? renderObjectValues(item) : item}
					</React.Fragment>
				))}
			</span>
		);
	};

	const renderObjectValues = (objData) => {
		return Object.entries(objData).map(([key, value]) => (
			<div key={key}>
				<Typography variant="body1" style={{ color: "#ff5722" }}>
					{Array.isArray(value) ? renderArrayValues(value) : value || "N/A"}
				</Typography>
			</div>
		));
	};

	const renderMediaItems = () => {
		if (!profileData?.media || profileData.media.length === 0) {
			return null;
		}

		if (profileData.media.length === 1) {
			const mediaItem = profileData.media[0];
			return (
				<div key={mediaItem.filename}>
					{mediaItem.type === "image" && (
						<React.Fragment>
							<CardMedia
								component="img"
								height="560"
								image={`/api/profiles/media/avatar/${mediaItem.filename}`}
								onContextMenu={(e) => e.preventDefault()}
								alt={`Media 1`}
								style={{
									maxWidth: "100%",
									objectFit: "contain",
									maxHeight: "none",
								}}
							/>
						</React.Fragment>
					)}
					{mediaItem.type === "video" && (
						<React.Fragment>
							<CardMedia
								component="video"
								controls
								height="560"
								src={`/api/profiles/media/avatar/${mediaItem.filename}`}
								onContextMenu={(e) => e.preventDefault()}
								controlsList="nodownload"
								alt={`Media 1`}
							/>
						</React.Fragment>
					)}
				</div>
			);
		}
		const sliderSettings = {
			dots: true,
			infinite: true,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
		};

		return (
			<Slider {...sliderSettings}>
				{profileData.media.map((mediaItem, index) => (
					<div key={index}>
						{mediaItem.type === "image" && (
							<React.Fragment>
								<CardMedia
									component="img"
									height="560"
									image={`/api/profiles/media/avatar/${mediaItem.filename}`}
									onContextMenu={(e) => e.preventDefault()}
									alt={`Media ${index + 1}`}
									style={{
										maxWidth: "100%",
										objectFit: "contain",
										maxHeight: "none",
									}}
								/>
							</React.Fragment>
						)}
						{mediaItem.type === "video" && (
							<React.Fragment>
								<CardMedia
									component="video"
									controls
									height="560"
									src={`/api/profiles/media/avatar/${mediaItem.filename}`}
									onContextMenu={(e) => e.preventDefault()}
									controlsList="nodownload"
									alt={`Media ${index + 1}`}
								/>
							</React.Fragment>
						)}
					</div>
				))}
			</Slider>
		);
	};
	return (
		<Container maxWidth="lg" sx={{ mt: 2 }}>
			<style>{sliderDotStyles}</style>
			<Card sx={{ mb: 2 }}>
				{loading && <LinearProgress />}
				{renderMediaItems()}
				<CardContent>
					{error ? (
						<div>{error}</div>
					) : (
						<React.Fragment>
							<Grid container spacing={2}>
								<Grid item xs={12} sm={4}>
									{profileData?.personalInformation?.height && (
										<Card sx={{ height: "100%" }}>
											<CardContent>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														justifyContent: "center",
														alignItems: "center",
														height: "100%",
													}}
												>
													<Typography
														variant="body1"
														align="center"
														color="textSecondary"
													>
														Height
													</Typography>
													<Typography
														variant="body1"
														align="center"
														color="primary"
													>
														{profileData?.personalInformation?.height}
													</Typography>
												</Box>
											</CardContent>
										</Card>
									)}
								</Grid>
								<Grid item xs={12} sm={4}>
									{profileData?.personalInformation?.residentCountry && (
										<Card sx={{ height: "100%" }}>
											<CardContent>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														justifyContent: "center",
														alignItems: "center",
														height: "100%",
													}}
												>
													<Typography
														variant="body1"
														align="center"
														color="textSecondary"
													>
														Resident Country
													</Typography>
													<Typography
														variant="body1"
														align="center"
														color="primary"
													>
														{profileData?.personalInformation?.residentCountry}
													</Typography>
												</Box>
											</CardContent>
										</Card>
									)}
								</Grid>
								<Grid item xs={12} sm={4}>
									{profileData?.educationCareer?.occupation && (
										<Card sx={{ height: "100%" }}>
											<CardContent>
												<Box
													sx={{
														display: "flex",
														flexDirection: "column",
														justifyContent: "center",
														alignItems: "center",
														height: "100%",
													}}
												>
													<Typography
														variant="body1"
														align="center"
														color="textSecondary"
													>
														Occupation
													</Typography>
													<Typography
														variant="body1"
														align="center"
														color="primary"
													>
														{profileData?.educationCareer?.occupation}
													</Typography>
												</Box>
											</CardContent>
										</Card>
									)}
								</Grid>
							</Grid>
							{profileData?.createdBy && (
								<Card sx={{ mt: 2 }}>
									<CardContent>
										{renderKeyValuePairs(
											filterProfileData(profileData?.createdBy)
										)}
									</CardContent>
								</Card>
							)}
							{profileData?.personalInformation && (
								<Card sx={{ mt: 2 }}>
									<CardContent>
										{renderKeyValuePairs(
											filterProfileData(profileData?.personalInformation)
										)}
									</CardContent>
								</Card>
							)}
							{profileData?.educationCareer && (
								<Card sx={{ mt: 2 }}>
									<CardContent>
										{renderKeyValuePairs(
											filterProfileData(profileData?.educationCareer)
										)}
									</CardContent>
								</Card>
							)}
							{profileData?.familyCulturalContext && (
								<Card sx={{ mt: 2 }}>
									<CardContent>
										{renderKeyValuePairs(
											filterProfileData(profileData?.familyCulturalContext)
										)}
									</CardContent>
								</Card>
							)}
							{profileData?.aFewLinesAboutYourself && (
								<Card sx={{ mt: 2 }}>
									<CardContent>
										{renderKeyValuePair(
											filterProfileData(profileData?.aFewLinesAboutYourself)
										)}
									</CardContent>
								</Card>
							)}
							{profileData?.anythingYouWantToShare && (
								<Card sx={{ mt: 2 }}>
									<CardContent>
										{renderKeyValuePairs(
											filterProfileData(profileData?.anythingYouWantToShare)
										)}
									</CardContent>
								</Card>
							)}
							{profileData?.partnerPreferences && (
								<Card sx={{ mt: 2 }}>
									<CardContent>
										{renderKeyValuePairs(
											filterProfileData(profileData?.partnerPreferences)
										)}
									</CardContent>
								</Card>
							)}
							<Actions>
								<Selection
									type="button"
									label="Back"
									variant="contained"
									startIcon={<ArrowBackIosIcon />}
									fullWidth={false}
									onClick={() => navigation(-1)}
								/>
								<Selection
									type="button"
									label="Chat with user"
									variant="contained"
									startIcon={<ArrowBackIosIcon />}
									fullWidth={false}
									onClick={() => InitiateChat(profileData)}
								/>
							</Actions>
						</React.Fragment>
					)}
				</CardContent>
			</Card>
		</Container>
	);
};

const filterProfileData = (data) => {
	if (!data) return null;
	const { height, residentCountry, occupation, ...filteredData } = data;
	return filteredData;
};

export default ViewCompleteProfile;

const Actions = styled.div`
	width: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 16px;
`;
